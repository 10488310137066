import Image from 'next/image';
import percentageIcon from '/public/vectors/percentage-icon.svg';

export function Discounts() {
	return (
		<div className="text-shadow-md w-full rounded-lg bg-yellow-secondary p-4 text-left text-sm">
			<div className="flex flex-row items-start gap-x-3 align-top">
				<Image
					src={percentageIcon}
					alt="Percentage icon"
					aria-hidden="true"
					className="size-6 shrink-0"
				/>
				<div className="pt-0.5">
					<strong>Discounts</strong>: We offer a <span className="rounded-sm bg-yellow-tertiary/20 px-0.5">
						50% discount
					</span>{' '} on classes and memberships for students and the employed.
                </div>
			</div>
		</div>
	);
}
